import { ReactComponent as AboutIcon } from "../icons/about.svg";
import { ReactComponent as EditingIcon } from "../icons/cut.svg";
import { ReactComponent as ProducingIcon } from "../icons/producing.svg";
import { ReactComponent as ResumeIcon } from "../icons/resume.svg";
import { ReactComponent as ContactIcon } from "../icons/email.svg";

export type Link = {
  text: string;
  destination: string;

  // links that are "static" are conventional links and open in a new tab
  static?: boolean;
  icon: React.ReactNode;
};

export const NAV_LINKS: Link[] = [
  { text: "about", destination: "/", icon: <AboutIcon /> },
  { text: "producing", destination: "/producing", icon: <ProducingIcon /> },
  { text: "editing", destination: "/editing", icon: <EditingIcon /> },
  {
    text: "resume",
    destination: "/Christina Choriatis Resume.pdf",
    icon: <ResumeIcon />,
    static: true,
  },
  { text: "contact", destination: "/contact", icon: <ContactIcon /> },
];
