import ReactMarkdown from "react-markdown";
import { Item } from "../common/Item";
import useWindowDimensions from "../hooks/useWindowDimensions";

export function getItems(items: readonly Item[], screenWidth: number) {
  const elements = [];

  let widthClass = "w-1/3";

  if (screenWidth < 550) {
    widthClass = "w-full";
  } else if (screenWidth < 750) {
    widthClass = "w-1/2";
  }

  for (const item of items) {
    const itemClassName = `item ${widthClass} p-4`;
    elements.push(
      <div className={itemClassName}>
        <div className="item-image mb-4">
          <a href={item.imageHref} target="_blank" rel="noreferrer noopener">
            <img src={item.imageUrl} alt={item.title} />
          </a>
        </div>
        <div className="item-details">
          <a href={item.imageHref} target="_blank" rel="noreferrer noopener">
            <h2 className="text-xl text-center font-semibold hover:underline">
              {item.title}
            </h2>
          </a>
          <div className="divider"></div>
          <ReactMarkdown className="text-sm">{item.description}</ReactMarkdown>
        </div>
      </div>
    );
  }
  return elements;
}

type ItemListProps = {
  items: Item[];
};

export function ItemList({ items }: ItemListProps) {
  const { width } = useWindowDimensions();
  return <div className="flex flex-wrap">{getItems(items, width)}</div>;
}
