import React from "react";
import "./App.css";
import { Frame } from "./widgets/Frame";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { About } from "./pages/About";
import { Editing } from "./pages/Editing";
import { Contact } from "./pages/Contact";
import { Producing } from "./pages/Producing";
import { CSSTransition, TransitionGroup } from "react-transition-group";

function App() {
  return (
    <div className="w-full flex justify-center">
      <Router>
        <Frame>
          <Route
            render={({ location }) => {
              return (
                <TransitionGroup>
                  <CSSTransition
                    key={location.key}
                    timeout={300}
                    classNames="fade"
                  >
                    <Switch location={location}>
                      <Route exact path="/">
                        <About />
                      </Route>
                      <Route path="/editing">
                        <Editing />
                      </Route>
                      <Route path="/contact">
                        <Contact />
                      </Route>
                      <Route path="/producing">
                        <Producing />
                      </Route>
                    </Switch>
                  </CSSTransition>
                </TransitionGroup>
              );
            }}
          />
        </Frame>
      </Router>
    </div>
  );
}

export default App;
