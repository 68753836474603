import { ReactComponent as EmailIcon } from "../icons/email.svg";
import { ReactComponent as PhoneIcon } from "../icons/phone.svg";
import { Footer } from "../widgets/Footer";

export function Contact() {
  return (
    <div className="page">
      <div className="p-4 max-w-screen-lg m-auto">
        <div className="text-2xl font-bold uppercase font-dark mb-4">
          Contact
        </div>
        <div>
          <div className="flex mb-4">
            <div className="mx-4">
              <PhoneIcon />
            </div>
            <a className="underline" href="tel:+1-248-202-0948">
              (248) 202 - 0948
            </a>
          </div>
          <div className="flex mb-4">
            <div className="mx-4">
              <EmailIcon />
            </div>
            <a className="underline" href="mailto:cchoriatis@gmail.com">
              cchoriatis@gmail.com
            </a>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
