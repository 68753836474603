import { Footer } from "../widgets/Footer";

export function About() {
  return (
    <div className="page">
      <div className="about-content p-4 max-w-screen-2xl m-auto ">
        <div className="image">
          <img
            src="christina_headshot.jpg"
            alt="Christina Choriatis at the top of a mountain."
          />
        </div>
        <div className="description">
          <div className="text-2xl font-bold uppercase font-dark mb-4">
            About Me
          </div>
          <p className="mb-4">
            Christina Choriatis is a NYC-based digital media producer with
            experience in live video production, virtual and livestream
            programming, video and audio editing, and post-production
            operations. She has been involved in all aspects of the content
            creation process from scheduling to booking crews to on-set
            producing for live-action video. Christina also has experience right
            in the editing room, overseeing edit schedules and asset
            deliverables during post-production. Christina has the coordination
            skills, as well as energy and enthusiasm, to take any project from
            concept to reality.{" "}
          </p>
          <p>
            Christina has a passion for working in non-fiction production with a
            desire to share stories of impact and showcase media that inspires
            and accelerates social change. From documentary programming to
            podcast productions, Christina’s mission is to create compelling and
            captivating work that will educate and inspire audiences.
          </p>
        </div>
      </div>
      <div className="spacer"></div>
      <div className="spacer"></div>
      <div className="spacer"></div>
      <div className="spacer"></div>
      <Footer />
    </div>
  );
}
