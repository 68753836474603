import { Item } from "../common/Item";
import { Footer } from "../widgets/Footer";
import { ItemList } from "../widgets/ItemList";

const ITEMS: Item[] = [
  {
    title: "Artificial Intelligence",
    description:
      "_Great Decisions in Foreign Policy_ is a full-length documentary series airing on PBS, where experts in international affairs explore critical global issues facing the world today. This episode dives into the latest developments in Artificial Intelligence spanning the globe.",
    imageUrl: "greatdecisionsplaybutton.jpg",
    imageHref: "https://player.vimeo.com/video/619968605?h=df6751cdf3",
  },
  {
    title: "Psychiatrist as Patient",
    description:
      "In partnership with the Depression and Bipolar Support Alliance, this documentary short follows the life of John Budin, MD, a respected psychiatrist who reveals his mental health condition after nearly 35 years.",
    imageUrl: "pyschiatristplaybutton.jpg",
    imageHref: "https://player.vimeo.com/video/430556693?h=de05931c18",
  },
  {
    title: "The Olympic Journey",
    description:
      "Athletes from the 2018 Winter Olympics in Pyeongchang reflect on their experience.",
    imageUrl: "olympicsplaybutton.jpg",
    imageHref: "https://player.vimeo.com/video/258704655?h=78b07feb83",
  },
  {
    title: "Hozier",
    description:
      "This excerpt from the PBS concert series, _Live From The Artists Den_, features musicians performing in unique and ornate venues.",
    imageUrl: "hozierplaybutton.jpg",
    imageHref: "https://player.vimeo.com/video/160452356?h=4ec2e93038",
  },
  {
    title: "Here at Gateway",
    description:
      "Students and staff at the Gateway Schools gather in a whimsical way to celebrate the pride of their school, set to the tune of the musical _Hairspray_.",
    imageUrl: "gatewayplaybutton.jpg",
    imageHref: "https://player.vimeo.com/video/278001581?h=f76bf93bec",
  },
  {
    title: "Hands Only CPR",
    description:
      "The Broadway cast of _Chicago_ teaches how you can save a life in three easy steps with Hands-Only-CPR.",
    imageUrl: "chicagoplaybutton.jpg",
    imageHref: "https://player.vimeo.com/video/258704857?h=50d92627f9",
  },
];

export function Editing() {
  return (
    <div className="page">
      <div className="p-4 max-w-screen-lg m-auto">
        <div className="text-2xl font-bold uppercase font-dark">Editing</div>
        <ItemList items={ITEMS} />
      </div>
      <div className="spacer"></div>
      <div className="spacer"></div>
      <div className="spacer"></div>
      <div className="spacer"></div>
      <Footer />
    </div>
  );
}
