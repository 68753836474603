import { Item } from "../common/Item";
import { Footer } from "../widgets/Footer";
import { ItemList } from "../widgets/ItemList";

const ITEMS: Item[] = [
  {
    title: "Tribeca Film Festival",
    description:
      "_The Tribeca Film Festival_ brings together film premieres, live events, and online web content for 12 days annually. My responsibilities included overseeing the production for live-streaming, red carpet coverage, and post-production press deliverables for the 2019 and 2021 events.",
    imageUrl: "tribecaplaybutton.jpeg",
    imageHref:
      "https://player.vimeo.com/video/355107138?app_id=122963&wmode=opaque&autoplay=1",
  },
  {
    title: "Women in the World",
    description:
      "_Women in the World_ was an annual live event focusing on women on the front lines of change, including guests such as Hilary Clinton, Oprah Winfrey, Meryl Streep, and Stacey Abrams. For four years, I directed the deliverable process of the event’s video/photo assets, which included over 40 one-minute introduction videos presented on-stage. Additionally, I worked along side the content capture team, supplementing livestream and press coverage of the event. Though this sizable event occurred annually, smaller gatherings occurred across the country during the year, shifting my responsibilities to focus on the intimate events.",
    imageUrl: "womenintheworld.jpg",
    imageHref: "https://www.tinabrownmedia.com/women-in-the-world",
  },
  {
    title: "Columbia University Commencement 2021",
    description:
      "In 2021, Columbia University's annual commencement graduation ceremony went virtual. As the client lead, I worked directly with Columbia to produce the virtual program, directly managing the pre-production, on-set operations, and creative logistics. Following production, I worked with the post-production team to finalize and deliver the hour long program that was streamed live to Columbia’s students on commencement day.",
    imageUrl: "columbia.jpg",
    imageHref: "https://youtu.be/L4MX76g9dWI",
  },
  {
    title: "New Yorker Festival 2020",
    description:
      "Adapting to the year’s climate, the annual arts festival _The New Yorker Festival_ went virtual in 2020, featuring appearances made by Steve Martin, Dr. Anthony Fauci, Yo-Yo Ma, and more. I collaborated with the event’s production team to develop the at-home virtual streaming kit. Additionally, I managed the equipment’s inventory and transit operations and developed solutions for the live programs post-production process. ",
    imageUrl: "newyorker.jpg",
    imageHref: "https://www.newyorker.com/video/series/the-new-yorker-festival",
  },
  {
    title: "Taft Media, Inc.",
    description:
      "Taft Media Inc., is a full-service production company dedicated to creating multimedia content across visual platforms. As founder, I developed a weekly documentary podcast, interviewing former fundamentalist church members about their wellness and deconstruction journey. As the executive producer and creator, I work daily with the team to oversee operations which make the weekly episode release possible, including scheduling and booking, guest relations, and post-production supervising. With 140,000+ listeners across streaming platforms such as Apple Podcasts, Spotify, and iHeartRadio, the podcast is a consistently growing project that I take deep pride in having been involved in since its conception.",
    imageUrl: "taft_optionb.jpg",
    imageHref: "google.com",
  },
  {
    title: "Pershing Square Sohn Cancer Research Alliance",
    description:
      "Annually, PSSCRA provides $200,000 to young scientists developing cancer research. Each winner receives a video profile in celebration of their accomplishment. For the past five years, I worked with PSSCRA to create the prize winner videos, coordinating the production of crews entering hospitals and labs, and oversaw the post-production of all prize winner segments and highlights.",
    imageUrl: "pershing.jpg",
    imageHref: "https://youtu.be/YygXnhzLZu0",
  },
];

export function Producing() {
  return (
    <div className="page">
      <div className="p-4 max-w-screen-lg m-auto">
        <div className="text-2xl font-bold uppercase font-dark">Producing</div>
        <p className="p-4">
          
        </p>
        <ItemList items={ITEMS} />
      </div>
      <div className="spacer"></div>
      <div className="spacer"></div>
      <div className="spacer"></div>
      <div className="spacer"></div>
      <Footer />
    </div>
  );
}
