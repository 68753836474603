import { Link, NavLink } from "react-router-dom";
import { NAV_LINKS } from "../common/Link";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { ReactComponent as HamburgerIcon } from "../icons/hamburger.svg";
import Drawer from "@mui/material/Drawer";
import { useState } from "react";

function desktopNavLinks() {
  return NAV_LINKS.map((link) => {
    if (link.static) {
      return (
        <a
          href={link.destination}
          target="_blank"
          className="uppercase"
          rel="noreferrer"
        >
          {link.text}
        </a>
      );
    }
    return (
      <NavLink
        exact
        activeClassName="border-b-2 border-dark"
        to={link.destination}
        className="uppercase"
      >
        {link.text}
      </NavLink>
    );
  });
}

function mobileNavLinks(onClick?: () => void) {
  return NAV_LINKS.map((link) => {
    if (link.static) {
      return (
        <div className="flex link-text p-4">
          {link.icon ? link.icon : undefined}
          <a
            href={link.destination}
            target="_blank"
            rel="noreferrer"
            className="uppercase flex ml-4 place-content-center font-extrabold font-dark"
          >
            <span>{link.text}</span>
          </a>
        </div>
      );
    }
    return (
      <div className="flex link-text p-4">
        {link.icon ? link.icon : undefined}
        <NavLink
          exact
          activeClassName="active"
          to={link.destination}
          className="uppercase flex ml-4 place-content-center font-extrabold font-dark"
          onClick={onClick}
        >
          <span>{link.text}</span>
        </NavLink>
      </div>
    );
  });
}

function DesktopFrame({ children }: any) {
  return (
    <div className="w-full max-w-screen-2xl">
      <div className="flex title">
        <Link to="/">
          <img
            className="p-4 h-full"
            src="cc_lockup.png"
            alt="Christina Choriatis Logo"
          />
        </Link>
        <div className="flex flex-1 justify-around items-center font-extrabold font-dark">
          {desktopNavLinks()}
        </div>
      </div>
      <div className="p-4 max-w-screen-2xl m-auto">{children}</div>
    </div>
  );
}

function MobileFrame({ children }: any) {
  const [drawerOpen, setDrawerOpen] = useState(false);
  return (
    <>
      <div className="w-full">
        <div className="flex title justify-between">
          <Link to="/">
            <img
              className="p-4 h-full"
              src="cc_lockup.png"
              alt="Christina Choriatis Logo"
            />
          </Link>
          <button
            onClick={() => {
              setDrawerOpen(true);
            }}
          >
            <HamburgerIcon className="mr-8" />
          </button>
        </div>
        {children}
      </div>
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
      >
        {mobileNavLinks(() => setDrawerOpen(false))}
      </Drawer>
    </>
  );
}

export function Frame({ children }: any) {
  const { width } = useWindowDimensions();
  if (width < 900) {
    return <MobileFrame>{children}</MobileFrame>;
  } else {
    return <DesktopFrame>{children}</DesktopFrame>;
  }
}
